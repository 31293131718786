<template>
  <div class="app-container" v-loading='isLoading'>
    <div class="leftBlock">
      <div class="add" @click="showAdd()" v-auth='"role:add"'> + 新增角色 </div>
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <el-radio-group class="group" v-model="radio" @change="getRolePermissions">
            <el-radio :label="item.id"><span> {{item.name }}</span>
            <span v-if="item.canDelete" @click="showVis(item)"> <i class="iconfont iconicon-05" v-auth='"role:delete"'></i></span></el-radio>
          </el-radio-group>
        </li>
      </ul>
    </div>
    <div class="rightBlock">
      <nav>
        <div class="title">角色权限</div> 
        <div> 
          <el-button v-if="canEdit" @click="canEditClick()" type="primary" v-auth='"role:edit"'>编辑</el-button> 
          <el-button v-if="!canEdit" @click="updatePermissions" type="primary" v-auth='"role:edit"'>保存</el-button> 
          <el-button v-if="!canEdit" @click="cancel" plain v-auth='"role:edit"'>取消</el-button> 
        </div>
      </nav>
      <div class="content" v-for="(item,index) in dataList" :key="item.id">
        <div class="left"> {{item.name}} </div> 
        <div class="right">
          <multi-check-list :dataList="item.children" :id="item.id+''" :disabled="canEdit" :invert="true" :isCheckAll='true' @change="handlerDataCheck"></multi-check-list>
        </div>
      </div>
    </div>
    <currency-tips :visible='currencyStatus' :confirmDialog='currencyConfirm' :cancelDialog='currencyDialog' :msg='tipMsg' :isdeling='isdeling'/>
    <add :visible='addStatus' :confirmDialog='addConfirm' :cancelDialog='addCancel' :list='list' :isdeling='isdeling'/>
  </div>
</template>

<script>
  import MultiCheckList from './components/multiCheckList.vue';
  import CurrencyTips from './dialog/currency';
  import Add from './dialog/add';
  import axios from 'axios';
  export default {
    components: { MultiCheckList , CurrencyTips , Add},
    data() {
      return {
        isLoading : false,
        radio : '1001',
        canEdit:true,
        // 提示信息 弹窗
        tipMsg : '亲，确定要删除该角色吗？',
        isdeling : false,
        currencyStatus : false,
        // 新增弹窗
        addStatus  : false,
        list:[
          {name:'账套管理员',id:'1',canDelete: false,},
          {name:'主管',id:'2',canDelete: false,},
          {name:'制单人',id:'3',canDelete: false,},
          {name:'查看',id:'4',canDelete: false,},
          {name:'自定义1',id:'5',canDelete: true,},
        ],

        dataList:[
          { 
            title:'凭证',
            id:'1',
            list:[{
              label:'电商',
              value:'1',
              // disabled: true,
              // isChecked:true,
              disabled: false,
              children:[{
                label:'淘宝',
                value:'11',
                isChecked:false,
                disabled: false,
              }, {
                label:'京东',
                value:'12',
                isChecked:false,
                disabled: false,
              }, {
                label:'亚马逊',
                value:'13',
                isChecked:true,
                disabled: true,
              }]
            }, {
              label:'生活服务',
              value:'2',
              isChecked:true,
              disabled: false,
              children:[{
                label:'美团',
                value:'21',
                isChecked:true,
                disabled: false,
              }, {
                label:'大众点评',
                value:'22',
                isChecked:true,
                disabled: false,
              }, {
                label:'滴滴',
                value:'23',
                isChecked:true,
                disabled: false,
              }, {
                label:'饿了么',
                value:'24',
                isChecked:true,
                disabled: false,
              },{
                label:'美团1',
                value:'25',
                isChecked:true,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'26',
                isChecked:true,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'27',
                isChecked:true,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'28',
                isChecked:true,
                disabled: false,
              },{
                label:'美团1',
                value:'28',
                isChecked:true,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'30',
                isChecked:true,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'31',
                isChecked:true,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'32',
                isChecked:true,
                disabled: false,
              },{
                label:'美团1',
                value:'33',
                isChecked:true,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'34',
                isChecked:true,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'35',
                isChecked:true,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'36',
                isChecked:true,
                disabled: false,
              }]
            }],
          },
          {
            title:'工资',
            id:'2',
            list:[{
              label:'电商',
              value:'a001',
              // disabled: true,
              children:[{
                label:'淘宝',
                value:'a002',
                isChecked:false,
                disabled: false,
              }, {
                label:'京东',
                value:'a003',
                isChecked:false,
                disabled: false,
              }, {
                label:'亚马逊',
                value:'a002',
                isChecked:true,
                disabled: true,
              }]
            }, {
              label:'生活服务',
              value:'a005',
              children:[{
                label:'美团',
                value:'a006',
                isChecked:false,
                disabled: false,
              }, {
                label:'大众点评',
                value:'a007',
                isChecked:false,
                disabled: false,
              }, {
                label:'滴滴',
                value:'a008',
                isChecked:false,
                disabled: false,
              }, {
                label:'饿了么',
                value:'a009',
                isChecked:false,
                disabled: false,
              },{
                label:'美团1',
                value:'a010',
                isChecked:false,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'a011',
                isChecked:false,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'a012',
                isChecked:false,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'a013',
                isChecked:false,
                disabled: false,
              },{
                label:'美团1',
                value:'a010',
                isChecked:false,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'a011',
                isChecked:false,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'a012',
                isChecked:false,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'a013',
                isChecked:false,
                disabled: false,
              },{
                label:'美团1',
                value:'a010',
                isChecked:false,
                disabled: false,
              }, {
                label:'大众点评1',
                value:'a011',
                isChecked:false,
                disabled: false,
              }, {
                label:'滴滴1',
                value:'a012',
                isChecked:false,
                disabled: false,
              }, {
                label:'饿了么1',
                value:'a013',
                isChecked:false,
                disabled: false,
              }
              ]
            }],
          },
        ],
        saveDataList :[],  //存储数据 编辑取消处理
        resultList :[],

        parentList:[],
        childList:[],
        checkList:[],
      }
    },
    methods: {
      arrayUnique(arr, name) {
          var hash = {};
          return arr.reduce(function (acc, cru,index) {
              if (!hash[cru[name]]) {
                  hash[cru[name]] = {index:index}
                  acc.push(cru)
              }else{
                  acc.splice(hash[cru[name]]['index'],1,cru)
              }
              return acc;
          }, []);
      },

      //新增弹窗
      showAdd(value){
        this.addStatus = true;
      },
      addConfirm(data){
        this.isdeling = true;
        this.$store.dispatch("AddRole", {
          companyId: this.getToken('companyId'),
          userId : this.getToken('userId'),
          ...data
        }).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.addStatus = false;
            this.getRoleList();
          } else {
            this.isdeling = false;
            this.addStatus = false;
            this.$message.error(res.msg)
          }
        })
      },
      addCancel(){
        this.addStatus = false;
      },

      //弹窗提示 删除
      showVis(item,value){
            // 方法
        this.item = item;
        this.currencyStatus = true;
      },
      currencyConfirm(){
        this.currencyStatus = false;
        this.isdeling = true;
        this.$store.dispatch("RemoveRole", {
          companyId: this.getToken('companyId'),
          userId : this.getToken('userId'),
          roleId : this.item.id,
        }).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.addStatus = false;
            this.getRoleList();
          } else {
            this.isdeling = false;
            this.addStatus = false;
            this.$message.error(res.msg)
          }
        })
      },
      currencyDialog(){
        this.currencyStatus = false;
      },

      handlerDataCheck(parent, child, id) {
        this.parentList = [...parent];
        this.childList = [...child];
        let data = {
          rootId:id,
          // parentList:[...parent],
          permissionIds:[...child],
        }
        this.checkList.push(data);
        console.log(this.checkList,'初始选中集合');
        // console.log(this.parentList,'父集');
        // console.log(this.childList,'子集');
        // console.log(id,'父集ID');
        let arr = this.arrayUnique(this.checkList,'rootId');
        this.resultList = this.arrayUnique(arr,'rootId');
        console.log(this.resultList,'选中集合');
      },
      //查询角色列表
      getRoleList() {
        this.isLoading = true;
        this.$store.dispatch("FindRoleList", {
          companyId: this.getToken('companyId'),
          userId : this.getToken('userId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.list = res.data;
            this.radio = res.data[0].id;
            this.getRolePermissions();
          } else {
            this.isLoading = false;
            this.$message.error(res.msg)
          }
        })
      },
      // 切换角色 || 查询角色权限详情
      getRolePermissions() {
        this.isLoading = true;
        this.$store.dispatch("RolePermissions", {
          companyId: this.getToken('companyId'),
          userId : this.getToken('userId'),
          roleId : this.radio,
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.canEdit = true;
            this.saveDataList = JSON.parse(JSON.stringify(res.data)); //深拷贝赋值
            this.dataList = res.data;
          } else {
            this.isLoading = false;
            this.$message.error(res.msg)
          }
        })
      },
      // 编辑角色权限
      canEditClick(value){
        this.canEdit = false
      },
      cancel(){
        this.dataList = JSON.parse(JSON.stringify(this.saveDataList));
        this.canEdit = true;
      },
      updatePermissions() {
        let url = '/api/euser/role/updatePermissions';
        let obj = {
            companyId: this.getToken('companyId'),
            permissionIdDtos: this.resultList ,
            roleId: this.radio,
            userId: this.getToken('userId'),
        }
        this.isLoading = true;
        axios.post(url, JSON.stringify(obj), {
            headers: {
              'Content-Type': "application/json;charset=utf-8",
              'Authorization': this.getCookies('Authorization'),
              'group' :process.env.VUE_APP_GROUP
            },
          }).then(res => {
            if (res.data.success) {
              this.resultList = [];
              this.isLoading = false;
              this.canEdit = true;
            }
          }
        )
      },
    },
    mounted() {
      this.getRoleList();
    },
  }
</script>

<style lang='less' scoped>
  .app-container{
    display: flex;
    height: auto !important;
    .leftBlock{
      width: 230px;
      margin-right: 18px;
      background: #fff;
      min-height: 39rem;
      .add{
        color: #fff;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        cursor: pointer;
        text-align: center;
        background: #4F71FF;
      }
      ul{
        margin: 10px 0;
        padding: 0 10px;
        li{
          height: 40px;
          line-height: 40px;
          .group{
            width: 100%;
            height: 100%;
            .iconfont{
              margin-right: 10px;
            }
          }
          /deep/ .el-radio__input{
            display: none;
          }
          /deep/
          .el-radio{
            width: 100%;
            height: 100%;
            .el-radio__label{
              width: 100%;
              display: flex;
              height: 100%;
              justify-content: space-between;
              align-items: center;
            }
            .el-radio__input.is-checked+.el-radio__label{
              background: #EDF1FF;
            }
          } 
        }
      }
    }
    .rightBlock{
      width: 100%;
      background: #fff;
      padding:0 16px;
      nav{
        height: auto;
        padding: 0;
        margin: 10px 0;
        .el-button{
          width: 100px;
          margin-left: 14px;
        }
      }
      .title{
        padding: 10px 0 10px 15px;
        position: relative;
        font-size: 0.94rem;
      }
      .title::after {
        content: "";
        width: 0.21rem;
        height: 0.83rem;
        background-color: #4f71ff;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .content{
        display: flex;
        border: 1px solid #dcdcdc;
        margin-top: 10px;
        margin-bottom: 5px;
        .left{
          background: #f0f0f0;
          padding: 0 10px;
          display: flex;
          width: 2.6rem;
          font-size: 16px;
          text-align: center;
          align-items: center;
          border-right: 1px solid #dcdcdc;
        }
        .right{
          background: #ffffff;
          width: 100%;
        }
      }
    }
  }
</style>
