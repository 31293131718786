<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialogs' :isdeling='isdeling'>
    <el-form ref="form" :rules="rules" :model="form" label-width="5rem">
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
      </el-form-item>
      <el-form-item label="复制权限" required>
        <el-select v-model="form.copyRoleId" placeholder="请选择复制权限">
           <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  export default {
    props: {
      list :{
        type : Array,
      },
      visible: {
        type: Boolean,
      },
      isdeling:{
        type: Boolean,
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
      cancelDialog:{
        type: Function,
        default: () => { },
      }
    },
    components: {
      Dialog,
    },
    data() {
      return {
        config: {
          top: '28vh',
          width: '21rem',
          title: '新建角色',
          center: true,
          btnTxt: ['确定','取消'],
        },
        roleList : [],
        form:{
          name : '',
          copyRoleId : '0',
        },
        rules: {
          name: [
            { required: true, message: "请输入角色名称" },
            { min: 1, max: 20, message: "角色名称长度在 1 到 20 个字符" , trigger: 'blur'},
          ],
        },
      };
    },
    methods: {
      confirmDialogs(){
        this.$refs.form.validate((valid) => {  //保存逻辑
          if (valid) {
            this.confirmDialog(this.form);
          }}
        )
      }
    },
    watch:{
      visible(val) {
        if(val){
          this.roleList = JSON.parse(JSON.stringify(this.list));
          this.roleList.unshift({
            id:'0',
            name :'不复制',
          })
        }else{
          this.form ={
            name : '',
            copyRoleId : '0',
          }
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .tips {
    text-align: center;
    margin-bottom: 0.8rem;
    margin-top: 2rem;
    line-height: 24px;
    font-size: 0.83rem;
  }
  .top {
    margin-top: 0;
  }
</style>