<template>
    <div class="multi-check" :style="{width:width}">
        <div class="multi-check-box" v-if="isCheckAll">
            <el-checkbox label="全选" class="multi-check-title" :disabled='disabled' :indeterminate="isIndeterminate" v-model="checkAll" @change="handlerChange(0, null, $event)">全选</el-checkbox>
             <!-- {{totalLabel}} -->
			<!-- <el-checkbox label="反选" v-model="checkInvert" @change="handlerChange(-1, null, $event)">反选</el-checkbox> -->
        </div>
        <div class="multi-check-box" v-for="(item) in dataList" :key="item.id">
            <el-checkbox class="multi-check-title" v-model="item.selected" :disabled='item.disabled || disabled' :indeterminate="item.isIndeterminate" :label="item.id" :value="item.id" @change="handlerChange(1, item, $event)">{{item.name}}</el-checkbox>
            <div class="multi-check-item">
                <el-checkbox v-model="child.selected" :disabled='child.disabled || disabled' @change="handlerChange(2, item, $event)" v-for="child in (item.children || [])" :key="child.id" :value="child.id">{{child.name}}</el-checkbox>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'multiCheckList',
    props: {
        isCheckAll: {  //是否显示全选
            type: Boolean,
            default: false
        },
        disabled:{
            type:Boolean,
			default:false
        },
        invert:{	   //是否显示反选
			type:Boolean,
			default:false
		},
        id:{  //集合绑定ID
            type: String,
        },
        dataList:{	   //数据集合
            type: Array,
        },
        width:{
            type: String,
            default:'100%'
        }
    },
    data() {
        return {
            checkAll:false,
            checkInvert:false,
            isIndeterminate:false,
            totalLabel:''
        }
    },
    watch: {
        'dataList': {
            handler() {
				const isChangeSum = this.dataList && this.dataList.reduce((prev, cur) => {
					return prev + (+(cur.selected || 0));
				}, 0);	//计算改变的个数
				const isCheckSum = this.dataList && this.dataList.reduce((prev, cur) => {
					return prev + (+(cur.selected || 0));
				}, 0);	//统计选择的次数
				this.checkAll = (isCheckSum && isCheckSum === this.dataList && this.dataList.length);
				this.isIndeterminate = !!isChangeSum;
				if(isCheckSum && isCheckSum === this.dataList && this.dataList.length) {
					this.isIndeterminate = false;
				}
                // this.totalLabel = isChangeSum === 0 ? "" : `已选择(${isChangeSum})个分类`;
			},
			immediate: true,
			deep:true
        }
    },
    computed: {
        getDataList() {
            let parentList = []
            let childList = []
            this.dataList.forEach(item => {
                if(item.selected) parentList.push(item.id);
                (item.children || []).forEach(child => {
					if(child.selected) {
						childList.push(child.id);
					}
				})
            })
            return [parentList, childList]
        }
    },
    methods: {
        handlerCheckAll(selected) {
            this.dataList.forEach(item => {
				this.$set(item, 'selected', selected);
				this.$set(item, 'isChange', selected);
				if(selected) this.$set(item, 'isIndeterminate', false);
				item.children.forEach(child => {
                    if(!child.disabled == true){
                        this.$set(child, 'selected', selected);
                    }
				})
			});
        },
        handlerInvertCheck() {
			this.dataList.forEach(item => {
				this.$set(item, 'isChange', !item.selected);
				this.$set(item, 'selected', !item.selected);
				item.children.forEach(child => {
					this.$set(child, 'selected', !child.selected);
					if(child.selected) this.$set(item, 'isChange', true);
				})
			});
		},
        handlerChange($type, $row, $event) {
            let selected = !$event.target ? $event: $event.target.checked;
            if($type === 0) {		//全选
                this.handlerCheckAll(selected)
                this.$emit('change', this.getDataList[0],this.getDataList[1],this.id)
                return
            }
            if($type === 1) {
                $row.children.forEach((item) => {
                    if(!item.disabled == true){
                        this.$set(item, 'selected', selected);
                    }
                })
            }
            if($type === -1) {	//反选
				this.handlerInvertCheck()
				this.$emit('change', this.getDataList[0],this.getDataList[1],this.id)
				return
			}
            const checkCount = $row.children.reduce((prev, cur)=> {
				let check = 0;
				if(cur.selected === undefined) {
					check = 0;
				}else {
					check = +cur.selected
				}
				return prev + (+check);
            },0);	//统计选择的次数
            this.$set($row, 'isChange', checkCount === 0 ? false: true);
            this.$set($row, 'selected', (checkCount && checkCount === $row.children.length) ? true: false);
            this.$set($row, 'isIndeterminate', (checkCount && checkCount < $row.children.length) ? true:false);
            this.$emit('change', this.getDataList[0],this.getDataList[1],this.id)
        }
    }
}
</script>
<style lang="less" scoped>
.multi-check {
    position: relative;
    text-align:left;
    .multi-check-title{
        min-width: 9.2rem !important ;
        margin-right: 0 !important;
        border-right: 1px solid #dcdcdc;
    }
    .multi-check-box{
        display: flex;
        line-height: 2.08rem;
        padding-left: 1.3rem;
        border-bottom: 1px solid #dcdcdc;
    }
    .multi-check-box:last-child{
        border-bottom: none;
    }
    .multi-check-item {
        width: 100%;
        background-color: #F9FAFB;
        line-height: 44px;
        padding-left: 25px;
        border-radius: 6px;
        /deep/ .el-checkbox{
            min-width: 4rem; 
            margin-right: 0.8rem;
            .el-checkbox__label{
                font-size: 15px;
            }
        }
    }
    /deep/   .el-checkbox__input.is-disabled+span.el-checkbox__label {
        color: #333;
        cursor: not-allowed;
    }
    /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner{
        background-color: #ddd;
        border-color: #AAA;
        cursor: not-allowed;
    }
    /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #AAA;
        border-color: #AAA;
    }
    /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #f5efef;
    }
}
</style>
